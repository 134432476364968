import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import HomeFeatured from '../Home/HomeFeatured'
import Blockquote from 'components/UIElements/Blockquote'
// Models
import { IInterviewCard } from 'models/Interview'
import { IArticleCard } from 'models/Article'
// Styles
import * as styles from './NotFoundContainer.module.css'

type NotFoundContainerProps = {
    interviews: IInterviewCard[]
    articles: IArticleCard[]
}

const NotFoundContainer: React.FC<NotFoundContainerProps> = ({
    interviews,
    articles,
}) => {
    return (
        <div className={styles.not_found}>
            <Content>
                <Blockquote>No encontramos la página que buscas</Blockquote>
                <HomeFeatured
                    items={interviews}
                    title="Pero quizás te interese..."
                    to="/interviews"
                />
                <HomeFeatured
                    items={articles}
                    title="Ponte al día"
                    to="/articles"
                />
            </Content>
            <DefaultSidebar />
        </div>
    )
}

export default NotFoundContainer
