import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
import NotFoundContainer from 'components/Sections/NotFound/NotFoundContainer'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IArticleCard } from 'models/Article'
import { IInterviewCard } from 'models/Interview'

type IndexPageProps = {
    pageSeo: ISeoQuery
    articles: { nodes: IArticleCard[] }
    interviews: { nodes: IInterviewCard[] }
}

const IndexPage: React.FC<PageProps<IndexPageProps>> = ({ data }) => {
    const articles = data.articles.nodes
    const interviews = data.interviews.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }

    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <NotFoundContainer articles={articles} interviews={interviews} />
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query NotFoundPageQuery {
        articles: allContentfulArticles(
            limit: 3
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                slug
                date
                thumbnail {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }

        interviews: allContentfulInterviews(
            limit: 3
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                slug
                date
                thumbnail {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "not-found" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
